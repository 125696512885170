<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-flex xs12>
              <span class="heading">Filter Data</span>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-start pt-2>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">From Date</span>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Date"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="itemform"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable :max="date">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">To Date</span>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="todate"
                        label="Date"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="itemform"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="todate" no-title scrollable :max="todate">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu1.save(todate)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">Status</span>
                  <v-autocomplete
                    outlined
                    label="Case Status"
                    :items="caseStatuslist"
                    v-model="casestatus"
                    class="itemform"
                    dense
                  >
                  </v-autocomplete>
                </v-flex>
                <!-- <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">Division</span>
                  <v-autocomplete
                    outlined
                    label="Division Name"
                    :items="divisionList"
                    v-model="divisionname"
                    item-text="divisionName"
                    item-value="_id"
                    class="itemform"
                    dense
                  >
                  </v-autocomplete>
                </v-flex> -->
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">Threat Level</span>
                  <v-select
                    :items="threatLevels"
                    label="Threat Level"
                    outlined
                    v-model="threatlevel"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
            </v-layout>
              <v-layout wrap justify-start>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2 v-if="appUser !== 'range' && appUser !== 'RRT' && appUser !== 'EmergencyUser'">
                  <span class="subheading">Range</span>
                  <v-autocomplete
                    outlined
                    label="Range Name"
                    :items="rangeList"
                    v-model="rangename"
                    item-text="rangeName"
                    item-value="_id"
                    class="itemform"
                    dense
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2 v-if="appUser !== 'range' && appUser !== 'RRT' && appUser !== 'EmergencyUser'">
                  <span class="subheading">RRT</span>
                  <v-autocomplete
                    outlined
                    label="RRT Name"
                    :items="rrtList"
                    v-model="rrtname"
                    item-text="name"
                    item-value="_id"
                    class="itemform"
                    :disabled="rangename ? false : true "
                    dense
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2 v-if="appUser === 'range' && appUser !== 'RRT'">
                  <span class="subheading">RRT</span>
                  <v-autocomplete
                    outlined
                    label="RRT Name"
                    :items="rrtfullList"
                    v-model="rrtname"
                    item-text="name"
                    item-value="_id"
                    class="itemform"
                    dense
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">Animal</span>
                  <v-select
                    :items="animalTypes"
                    outlined
                    item-text="name"
                    item-value="_id"
                    label="Animal Type"
                    v-model="animaltype"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>   
                  <span class="subheading">Scenario</span>
                  <v-select
                    :items="scenariodata"
                    outlined
                    item-text="name"
                    item-value="_id"
                    label="Scenario Type"
                    v-model="scenarioname"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start>
                <!-- <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">Threat Level</span>
                  <v-select
                    :items="threatLevels"
                    label="Threat Level"
                    outlined
                    v-model="threatlevel"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex> -->
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2 align-self-center>
                      <v-btn block depressed color="primary" class="itemform" @click="getData()">
                          <span>Apply Filters</span>
                      </v-btn>
                  </v-flex>
              </v-layout>
              <!-- <v-layout wrap justify-start>
                  <v-flex xs12 lg3 pl-lg-2>
                      <v-btn block depressed color="primary" class="itemform" @click="getData()">
                          <span>Apply Filters</span>
                      </v-btn>
                  </v-flex>
              </v-layout> -->
            </v-flex>
            <v-flex xs12 v-if="items && items.length > 0">
              <v-layout wrap justify-start pt-2>
                <v-flex xs12>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tableheading">Reported Date</th>
                          <th class="text-left tableheading">Division</th>
                          <th class="text-left tableheading">Range</th>
                          <th class="text-left tableheading">Location</th>
                          <th class="text-left tableheading">Animal</th>
                          <th class="text-left tableheading">Scenario</th>
                          <th class="text-left tableheading">Threat Level</th>
                          <th class="text-left tableheading">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="tablesubheading">{{ formatDate(item.reportDate) }}</td>
                          <td class="tablesubheading">
                          <span v-for="(item1, i) in item.divisions"
                          :key="i">
                          <span v-if="item1.divisionName"
                            >{{ item1.divisionName }}
                            <span
                              v-if="i < item.divisions.length - 1"
                              >,
                            </span></span
                          >
                          </span>
                        </td>
                        <td class="tablesubheading">
                          <span v-for="(item2, i) in item.ranges"
                          :key="i">
                          <span v-if="item2.rangeName"
                            >{{ item2.rangeName }}
                            <span
                              v-if="i < item.ranges.length - 1"
                              >,
                            </span></span
                          >
                          </span>
                        </td>
                          <td class="tablesubheading">{{ item.locationname }}</td>
                          <td class="tablesubheading">{{ item.animalId.name }}</td>
                          <td class="tablesubheading">{{ item.scenarioId.name }}</td>
                          <td class="tablesubheading">{{ item.threatlevel }}</td>
                          <td class="tablesubheading">
                            <span v-if="casestatus !== 'Resolved'">
                              <v-btn
                              color="success"
                              depressed
                              small
                              @click="openviewdetails(item)"
                              ><span>View Details</span></v-btn
                            >
                            </span>
                            <span v-if="casestatus === 'Resolved'">
                              <v-btn
                              color="success"
                              depressed
                              small
                              @click="openresolvedviewdetails(item)"
                              ><span>View Details</span></v-btn
                            >
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="items && items.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                circle
                color="#427D2D"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else pl-lg-3>
          <v-layout wrap justify-start pl-4>
            <v-flex xs12 class="text-left">
              <span class="heading">No Data Found!Apply Filters.</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Delete Rescuer</v-card-title>
          <v-card-text>
            Are you sure you want to delete this rescuer?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="deleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteUser()"
              class="itemValue"
            >
              <span style="color: #fff">Confirm</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
            <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        count: 20,
        keyword: "",
        currentPage: 1,
        Pagelength: 0,
        userlist: [],
        districts: [
          "All",
          "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
        ],
        deleteDialog: false,
        items: [],
        divisionname: "",
        divisionList: [],
        rangeList: [],
        rangename: "",
        rrtname: "",
        rrtList: [],
        rrtfullList:[],
        animalTypes: [],
        animaltype: "",
        scenariodata: [],
        scenarioname: "",
        casestatus: "Active",
        caseStatuslist: [
          "Active",
          "Assigned",
          "Resolved",
          "Released",
        ],
        menu: false,
        menu1: false,
        todate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        threatLevels: ["High", "Medium", "Low"],
        threatlevel:"",
        
  
      };
    },
    beforeMount() {
      this.getData();
      this.getDivisionData();
      this.getRangeData();
      // this.getRRTData();
      this.getRRTFULLData();
      this.getanimalData();
      this.getscenarioData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      casestatus(){
        this.getData();
      },
      rangename(){
        this.getRRTData();
      },
      count() {
        this.getData();
      },
      keyword() {
        this.getData();
        this.currentPage=1
      },
    },
    computed: {
        appUser() {
          return this.$store.state.userRole;
        },
      },
    methods: {
      openviewdetails(item) {
        this.$router.push({
          path: '/Admin/cases/caseDetails/?id=',
          query: {
            id: item._id,
          },
        });
      },
      openresolvedviewdetails(item) {
    this.$router.push({
      path: '/Admin/cases/caseDetails',
      query: {
        id: item.reportId,
        status: "Resolved", // Add the status parameter here
      },
    });
      },  
      getData() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/hwc/report/alllist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            division:this.divisionname,
            range:this.rangename,
            rrt:this.rrtname,
            animalId:this.animaltype,
            scenarioId:this.scenarioname,
            status:this.casestatus,
            fromDate:this.date,
            toDate:this.todate,
            page:this.currentPage,
            limit:this.count,
            threatlevel:this.threatlevel,
  
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.items = response.data.data;
                // var temp = [];
                // for (var i = 0; i < this.items.length; i++) {
                //   if (this.items[i].reportId != null)
                //     var it = this.items[i].reportId;
                //   it._id = this.items[i]._id;
                //   it.reportedAt = this.items[i].reportedAt;
                //   if (this.items[i].reportId != null) {
                //     if (this.items[i].reportId.snake)
                //       it.snake = this.items[i].reportId.snake.name;
                //   }
                //   temp.push(it);
                // }
                // this.items = temp;
                this.Pagelength = Math.ceil(response.data.totalLength/this.count);
                this.msg = response.data.msg;
                this.showSnackBar = true;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      getDivisionData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/platform/division/getlistdropdown",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.divisionList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRangeData() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/platform/range/getlistdropdown",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.rangeList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRRTData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/rescuer/rrt/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params:{
            division:this.divisionname,
            range:this.rangename,
          }
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.rrtList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRRTFULLData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/rescuer/rrt/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.rrtfullList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getanimalData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/animal/list",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.animalTypes = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getscenarioData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/scenario/list",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.scenariodata = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      formatDate(date) {
        if (!date) return "-";
        const [year, month, day] = date.slice(0, 10).split("-");
        return `${day}-${month}-${year}`;
      },
    },
  };
  </script>
            <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  .tableheading {
    font-family: poppinsregular;
    font-size: 20px;
  }
  .tablesubheading {
    font-family: poppinsregular;
    font-size: 20px;
  }
  
  </style>